export const APP = {
    LOADING: 'LOADING',
    BUTTON_LOADING: 'BUTTON_LOADING',

    ERROR_SNACK_OPEN: 'ERROR_SNACK_OPEN',
    ERROR_SNACK_CLOSE: 'ERROR_SNACK_CLOSE',

    SUCCESS_SNACK_OPEN: 'SUCCESS_SNACK_OPEN',
    SUCCESS_SNACK_CLOSE: 'SUCCESS_SNACK_CLOSE'
};

export const AUTH = {
    SIGN_IN: 'SIGN_IN',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAIL: 'SIGN_IN_FAIL',

    SIGN_UP: 'SIGN_UP',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAIL: 'SIGN_UP_FAIL',

    EMAIL_VER: 'EMAIL_VER',
    EMAIL_VER_SUCCESS: 'EMAIL_VER_SUCCESS',
    EMAIL_VER_FAIL: 'EMAIL_VER_FAIL',

    PASS_RECOVERY: 'PASS_RECOVERY',
    PASS_RECOVERY_SUCCESS: 'PASS_RECOVERY_SUCCESS',
    PASS_RECOVERY_FAIL: 'PASS_RECOVERY_FAIL',

    PASS_CONFIRM: 'PASS_CONFIRM',
    PASS_CONFIRM_SUCCESS: 'PASS_CONFIRM_SUCCESS',
    PASS_CONFIRM_FAIL: 'PASS_CONFIRM_FAIL',

    USER_INFO: 'USER_INFO',
    USER_INFO_SUCCESS: 'USER_INFO_SUCCESS',
    USER_INFO_FAIL: 'USER_INFO_FAIL'
};

export const HOME = {
    CAT_LIST: 'CAT_LIST',
    CAT_LIST_SUCCESS: 'CAT_LIST_SUCCESS',
    CAT_LIST_FAIL: 'CAT_LIST_FAIL',

    CATEGORY: 'CATEGORY',
    CATEGORY_SUCCESS: 'CATEGORY_SUCCESS',
    CATEGORY_FAIL: 'CATEGORY_FAIL',

    PRODUCTS: 'PRODUCTS',
    PRODUCTS_SUCCESS: 'PRODUCTS_SUCCESS',
    PRODUCTS_FAIL: 'PRODUCTS_FAIL',

    PRODUCT_ITEM: 'PRODUCT_ITEM',
    PRODUCT_ITEM_SUCCESS: 'PRODUCT_ITEM_SUCCESS',
    PRODUCT_ITEM_FAIL: 'PRODUCT_ITEM_FAIL',

    CONTRACT: 'CONTRACT',
    CONTRACT_SUCCESS: 'CONTRACT_SUCCESS',
    CONTRACT_FAIL: 'CONTRACT_FAIL'
};

export const SUPPLY = {
    CREATE_CONTRACT: 'CREATE_CONTRACT',

    GET_CONTRACT: 'GET_CONTRACT',
    GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',

    DOWNLOAD_FILE: 'DOWNLOAD_FILE',

    GET_LIST_CONTRACTS: 'GET_LIST_CONTRACTS',
    GET_LIST_CONTRACTS_SUCCESS: 'GET_LIST_CONTRACTS_SUCCESS',
    GET_LIST_CONTRACTS_FAIL: 'GET_LIST_CONTRACTS_FAIL',

    GET_LIST_INVESTMENTS: 'GET_LIST_INVESTMENTS',
    GET_LIST_INVESTMENTS_SUCCESS: 'GET_LIST_INVESTMENTS_SUCCESS',
    GET_LIST_INVESTMENTS_FAIL: 'GET_LIST_INVESTMENTS_FAIL',

    OFFER_PRODUCT: 'OFFER_PRODUCT',
    OFFER_PRODUCT_SUCCESS: 'OFFER_PRODUCT_SUCCESS',
    OFFER_PRODUCT_FAIL: 'OFFER_PRODUCT_FAIL'
};
