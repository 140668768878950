import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {reducer as formReducer} from 'redux-form';
import authReducer from './authReducer';
import appReducer from './appReducer';
import homeReducer from './homeReducer';
import supplyReducer from './supplyReducer';

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        form: formReducer,
        auth: authReducer,
        app: appReducer,
        home: homeReducer,
        supply: supplyReducer
    });

export default rootReducer;
