import {SUPPLY} from './actionTypes';

export function createSupplyCotract(data) {
    return {
        type: SUPPLY.CREATE_CONTRACT,
        payload: {
            client: 'default',
            request: {
                url: `contract/supplier/`,
                method: 'post',
                data
            }
        }
    };
}

export function getSupplyContract(id) {
    return {
        type: SUPPLY.GET_CONTRACT,
        payload: {
            client: 'default',
            request: {
                url: `contract/supplier/${id}/`,
                method: 'get'
            }
        }
    };
}

export function downloadSupplyFile(id, data) {
    return {
        type: SUPPLY.DOWNLOAD_FILE,
        payload: {
            client: 'default',
            request: {
                url: `contract/supplier/${id}/`,
                method: 'put',
                data
            }
        }
    };
}

export function getListSupplyContracts(page = 1) {
    return {
        type: SUPPLY.GET_LIST_CONTRACTS,
        payload: {
            client: 'default',
            request: {
                url: `contract/supplier/?page=${page}`,
                method: 'get'
            }
        }
    };
}

export function getListInvestmentsContracts(page = 1) {
    return {
        type: SUPPLY.GET_LIST_INVESTMENTS,
        payload: {
            client: 'default',
            request: {
                url: `contract/investment/?page=${page}`,
                method: 'get'
            }
        }
    };
}

export function downloadInvestmentFile(id, data) {
    return {
        type: SUPPLY.DOWNLOAD_FILE,
        payload: {
            client: 'default',
            request: {
                url: `contract/investment/${id}/`,
                method: 'put',
                data
            }
        }
    };
}

export function postOfferProduct(data) {
    return {
        type: SUPPLY.OFFER_PRODUCT,
        payload: {
            client: 'default',
            request: {
                url: `contract/supplier/`,
                method: 'post',
                data
            }
        }
    };
}
