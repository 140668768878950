import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {postContract} from '../../actions/homeActions';
import {change, reduxForm, SubmissionError} from 'redux-form';
import SupplyFields from './SupplyFields';
import InvestFields from './InvestFields';
import ContractPageInfo from './ContractPageInfo';
import DefaultButton from '../HelperComponents/Buttons/DefaultButton/DefaultButton';
import ContractPageDialog from './ContractPageDialog';

import './ContractPage.scss';

import {ReactComponent as ArrowBlueIcon} from '../../assets/images/arrow-blue.svg';

const ContractPage = ({type, handleSubmit, submitting, pristine, invalid}) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const {buttonLoading} = useSelector(({app}) => app);

    const productId = location && location.state && location.state.id;
    const categoryName = location && location.state && location.state.catName;
    const productTitle = location && location.state && location.state.title;

    useEffect(() => {
        if (
            (type === 'investment' && !productId) ||
            (type === 'supplier' && (!productId || !categoryName || !productTitle))
        ) {
            history.push('/main/home');
        }

        if (type === 'investment') {
            dispatch(change('ContractPage', 'product', productId));
        } else if (type === 'supplier') {
            dispatch(change('ContractPage', 'product', productId));
            dispatch(change('ContractPage', 'product_category', categoryName));
            dispatch(change('ContractPage', 'product_name', productTitle));
        }
    }, []);

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => history.push('/main/home');

    const submitForm = (data) => {
        return dispatch(postContract(data, type)).then((res) => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                setDialog(true);
            } else {
                throw new SubmissionError({...res.error.response.data, _error: res.error.response.data});
            }
        });
    };

    return (
        <main className='contract-page page-wrap'>
            <form className='container py-40' onSubmit={handleSubmit(submitForm)}>
                <section className='contract-page__section mb-40 p-40'>
                    <button type='button' className='backlink mb-10' onClick={() => history.goBack()}>
                        <ArrowBlueIcon />
                        <span>Back</span>
                    </button>
                    <h1 className='h1 mb-55'>
                        {type === 'investment' && `Invest in ${productTitle}`}
                        {type === 'supplier' && 'Supply in this category'}
                    </h1>

                    <div className='contract-page__fields max-w-690'>
                        {type === 'investment' && <InvestFields />}
                        {type === 'supplier' && <SupplyFields />}
                    </div>
                </section>

                <section className='contract-page__section mb-40 p-40'>
                    <ContractPageInfo />
                    <DefaultButton
                        className='contract-page__submit'
                        disabled={submitting || pristine || invalid}
                        loading={buttonLoading}
                        formAction
                    >
                        Agree & send request
                    </DefaultButton>
                </section>
            </form>

            <ContractPageDialog open={dialog} onClose={closeDialog} />
        </main>
    );
};

export default reduxForm({
    form: 'ContractPage'
})(ContractPage);
