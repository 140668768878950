import React from 'react';
import {Field} from 'redux-form';
import RenderField from '../HelperComponents/RenderField/RenderField';
import {required} from '../../helpers/formValidation';

import {ReactComponent as CheckMarkIcon} from '../../assets/images/check-mark.svg';

const SupplyFields = () => {
    return (
        <>
            <Field
                component={RenderField}
                className='contract-page__input mb-70 w-50'
                name='product_category'
                label='Product category (optional)'
                readOnly
                symbol={<CheckMarkIcon />}
            />
            <Field
                component={RenderField}
                className='contract-page__input mb-55 w-100'
                name='product_name'
                label='Product name'
                readOnly
                symbol={<CheckMarkIcon />}
            />
            <Field
                component={RenderField}
                className='contract-page__input mb-55 w-100'
                name='product_specifications'
                label='Product specifications'
                placeholder='Please enter the specifications of the product you want to supply...'
                validate={required}
                multiline
            />
            <Field
                component={RenderField}
                className='contract-page__input mb-55 w-100'
                type='number'
                name='quantity'
                label='Quantity'
                placeholder='Please enter the quantity of the product you want to supply...'
                validate={required}
            />
            <Field
                component={RenderField}
                className='contract-page__input mb-55 w-100'
                type='number'
                name='price'
                label='Price'
                placeholder='Please enter the price of the product you want to supply...'
                validate={required}
            />
            <Field
                component={RenderField}
                className='contract-page__input w-100'
                name='brand'
                label='Brand'
                placeholder='Please enter the brand of the product you want to supply...'
                validate={required}
            />
        </>
    );
};

export default SupplyFields;
