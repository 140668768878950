import React from 'react';
import {Context as ResponsiveContext} from 'react-responsive';
import {toast} from 'react-toastify';

import '../assets/styles/main.scss';

toast.configure();

const App = (props) => {
    return <ResponsiveContext.Provider>{props.children}</ResponsiveContext.Provider>;
};

export default App;
