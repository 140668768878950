import React from 'react';
import NotFoundIcon from '../../assets/images/404-error.png';
import './NotFound.scss';

const NotFound = ({text}) => {
    return (
        <div className='page-not-found w-100 h-100'>
            <img className='page-not-found__img max-w-640 m-auto' src={NotFoundIcon} alt='not found' />
            {text && <h2 className='page-not-found__title'>{text}</h2>}
        </div>
    );
};

export default NotFound;
