import {takeEvery} from 'redux-saga/effects';
import {disableButtonLoader, enableButtonLoader, openErrorSnack} from './rootSaga';

export function* handleSupplyLoaders() {
    yield takeEvery('OFFER_PRODUCT', enableButtonLoader);
    yield takeEvery('OFFER_PRODUCT_SUCCESS', disableButtonLoader);
    yield takeEvery('OFFER_PRODUCT_FAIL', disableButtonLoader);

    yield takeEvery('GET_LIST_CONTRACTS', enableButtonLoader);
    yield takeEvery('GET_LIST_CONTRACTS_SUCCESS', disableButtonLoader);
    yield takeEvery('GET_LIST_CONTRACTS_FAIL', disableButtonLoader);

    yield takeEvery('GET_LIST_INVESTMENTS', enableButtonLoader);
    yield takeEvery('GET_LIST_INVESTMENTS_SUCCESS', disableButtonLoader);
    yield takeEvery('GET_LIST_INVESTMENTS_FAIL', disableButtonLoader);
}

export function* handleSupplyErrors() {
    yield takeEvery('OFFER_PRODUCT_FAIL', openErrorSnack);
}
