import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import './DialogComponent.scss';

import {ReactComponent as CloseIcon} from './icons/close.svg';

const DialogComponent = ({open, onClose, className = '', children}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                root: `default-dialog-root${className && ` ${className}`}`,
                paper: 'paper-custom-dialog'
            }}
        >
            <div className='dialog-wrapper'>
                <button onClick={onClose} className='dialog-close-button'>
                    <CloseIcon />
                </button>
                {children}
            </div>
        </Dialog>
    );
};

export default DialogComponent;
