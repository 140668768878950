import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import CardWithStepper from '../HelperComponents/CardWithStepper/CardWithStepper';
import {connect} from 'react-redux';
import {getListSupplyContracts, downloadSupplyFile} from '../../actions/supplyActions';
import {useHistory} from 'react-router';
import Pagination from '../HelperComponents/Pagination/Pagination';
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock';

import './SuppliesPage.scss';

import {ReactComponent as HeartIcon} from '../../assets/images/heart.svg';

const SuppliesPage = ({getListSupplyContracts, contractsList, downloadSupplyFile, userInfo}) => {
    const [activePage, setActivePage] = useState(1);
    const history = useHistory();

    useEffect(() => {
        getListSupplyContracts();
    }, []);

    useEffect(() => {
        userInfo && userInfo.role && userInfo.role !== 'company' && history.push('/main/home');
    }, [userInfo]);

    const checkStatus = (status) => {
        switch (status) {
            case 'in_review':
                return 1;
            case 'signature_required':
                return 2;
            case 'contract_in_review':
                return 3;
            case 'investment_received':
                return 4;
            case 'production_process':
                return 5;
            case 'shipping_process':
                return 6;
            case 'customs_clearance':
                return 7;
            case 'fulfillment_center':
                return 8;
            case 'already_delivered':
                return 9;
            case 'payment_received':
                return 10;
            case 'success':
                return 11;
            default:
                return null;
        }
    };

    return (
        <main className='supplies-page page-wrap'>
            <div className='supplies-page__msg min-h-60'>
                <div className='supplies-page__msg-container container py-20'>
                    <HeartIcon />
                    <p className='ml-15 mr-10'>Fill out a simple form and provide a possible assistance to society!</p>
                    <Link to='/main/my-supplies/offer-product'>Offer my own product</Link>
                </div>
            </div>

            <div className='supplies-page__list container py-40'>
                {contractsList &&
                    contractsList.results &&
                    contractsList.results.map((el) => (
                        <CardWithStepper
                            key={el.id}
                            id={el.id}
                            status={checkStatus(el.status)}
                            name={(el.product_data && el.product_data.title) || el.product_name}
                            product_data={el.product_data}
                            fulfillment_center={el.fulfillment_center}
                            already_delivered={el.already_delivered}
                            offer={el.offer}
                            downloadFile={downloadSupplyFile}
                            getData={() => getListSupplyContracts(activePage)}
                        />
                    ))}
                {contractsList.results && contractsList.results.length === 0 && (
                    <TransitionedBlock tag='h2' className='h1 w-100 t-center'>
                        <span>You made no supplies yet :(</span>
                    </TransitionedBlock>
                )}
                {/* <CardWithStepper status={1} />
                <CardWithStepper status={2} />
                <CardWithStepper status={3} />
                <CardWithStepper status={4} /> */}
            </div>

            {contractsList && contractsList.count > 3 && (
                <div className='supplies-page__pagination container mb-60'>
                    <Pagination
                        pageCount={contractsList && Math.ceil(contractsList.count / 3)}
                        count={contractsList && contractsList.count}
                        pageLength={contractsList && contractsList.results && contractsList.results.length}
                        active={activePage - 1}
                        onChange={(e) => {
                            setActivePage(e.selected + 1);
                            getListSupplyContracts(e.selected + 1);
                            window.scrollTo({
                                top: 0
                            });
                        }}
                    />
                </div>
            )}
        </main>
    );
};

const mapStateToProps = ({supply, auth}) => {
    return {
        contractsList: supply.contractsList,
        userInfo: auth.userInfo
    };
};

const mapDispatchToProps = {getListSupplyContracts, downloadSupplyFile};

export default connect(mapStateToProps, mapDispatchToProps)(SuppliesPage);
