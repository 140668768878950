import React, {useEffect} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {postEmailVer} from '../../../actions/authActions';
import {useQueryParams, useToggle} from '../../../helpers/functions';
import DialogComponent from '../../HelperComponents/DialogComponent/DialogComponent';
import DefaultButton from '../../HelperComponents/Buttons/DefaultButton/DefaultButton';
import LoaderMUI from '../../HelperComponents/LoaderMUI/LoaderMUI';

const EmailVerification = () => {
    const dispatch = useDispatch();
    const {loading} = useSelector(({app}) => app);
    const {emailVerError} = useSelector(({auth}) => auth);
    const history = useHistory();
    const queryParams = useQueryParams();

    const [dialog, toggleDialog] = useToggle(false);

    const deleteQuery = () => {
        queryParams.delete('security_token');
        history.replace({search: queryParams.toString()});
        toggleDialog();
    };

    useEffect(() => {
        if (queryParams.has('security_token')) {
            dispatch(postEmailVer({security_token: queryParams.get('security_token')}));
            toggleDialog();
        }
    }, []);

    if (loading) {
        return <LoaderMUI />;
    }

    return (
        <DialogComponent open={dialog} onClose={toggleDialog} title='Something wrong'>
            <h1 className='h1 mb-35'>{emailVerError ? 'Something wrong' : 'Thank you for your registration'}</h1>
            {emailVerError ? (
                <p className='mb-30'>Error name: {emailVerError}</p>
            ) : (
                <>
                    <p className='mb-5'>Your details have been sent to our team and are currently under review.</p>
                    <p className='mb-30'>You will soon receive a confirmation with log in details.</p>
                </>
            )}
            <DefaultButton className='small-btn' variant='outlined' onClick={deleteQuery}>
                Ok
            </DefaultButton>
        </DialogComponent>
    );
};

export default EmailVerification;
