import React from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import './DefaultButton.scss';

const DefaultButton = (
    {
        children,
        variant = 'contained',
        type = 'button',
        to,
        formAction,
        disabled,
        onClick,
        loading,
        className = ''
    }) => {
    if (type === 'link') {
        return (
            <span className='default-button-wrapper'>
                <Button
                    component={Link}
                    to={to}
                    variant={variant}
                    classes={{
                        root: `default-button default-button-${variant} ${className}`
                    }}
                >
                    {children}
                </Button>
            </span>
        );
    } else {
        return (
            <span className='default-button-wrapper'>
                <Button
                    type={formAction ? 'submit' : 'button'}
                    variant={variant}
                    disabled={disabled}
                    onClick={onClick}
                    classes={{
                        root: `default-button default-button-${variant} ${className}`
                    }}
                >
                    {loading ? <CircularProgress size={24} /> : children}
                </Button>
            </span>
        );
    }
};

export default DefaultButton;
