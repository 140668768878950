import {SUPPLY} from '../actions/actionTypes';

const INITIAL_STATE = {
    contract: {},
    contractsList: {},
    investmentsList: {}
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SUPPLY.GET_CONTRACT_SUCCESS:
            return {...state, contract: action.payload.data};
        case SUPPLY.GET_LIST_CONTRACTS_SUCCESS:
            return {...state, contractsList: action.payload.data};
        case SUPPLY.GET_LIST_INVESTMENTS_SUCCESS:
            return {...state, investmentsList: action.payload.data};
        default:
            return state;
    }
}
