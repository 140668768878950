import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink, useRouteMatch} from 'react-router-dom';

import './CategoryNav.scss';

const CategoryNav = ({className = '', rootPath = '/main/home', allLink = true, links = []}) => {
    const productUrl = useRouteMatch(`${rootPath}/product-:id`);
    const productCategory = useSelector(({home}) => home.productItem.category);

    return (
        <nav className={`cat-nav${className && ` ${className}`}`}>
            {allLink && (
                <NavLink
                    className='cat-nav__link'
                    to={rootPath}
                    isActive={(match, location) => location.pathname + location.search === rootPath}
                >
                    All
                </NavLink>
            )}

            {links &&
                links.map(({id, name}) => (
                    <NavLink
                        key={id}
                        className='cat-nav__link'
                        to={`${rootPath}?category=${id}`}
                        isActive={(match, location) =>
                            location.pathname + location.search === `${rootPath}?category=${id}` ||
                            (productUrl && productCategory === id)
                        }
                        exact
                    >
                        {name}
                    </NavLink>
                ))}
        </nav>
    );
};

export default CategoryNav;
