import React, {useEffect} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import {notifyError, notifySuccess} from '../../helpers/notifySnack';
import 'react-toastify/dist/ReactToastify.css';
import {ScrollToTop} from '../../helpers/functions';

import HomePage from '../../components/HomePage/HomePage';
import NotFound from '../../components/NotFound/NotFound';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ProductPage from '../../components/ProductPage/ProductPage';
import ContractPage from '../../components/ContractPage/ContractPage';
import SuppliesPage from '../../components/SuppliesPage/SuppliesPage';
import OfferProduct from '../../components/SuppliesPage/OfferProduct/OfferProduct';
import InvestmentsPage from '../../components/InvestmentsPage/InvestmentsPage';

const Container = ({match}) => {
    const {errorSnack, errorSnackText, successSnack, successSnackText} = useSelector(({app}) => app);

    useEffect(() => {
        if (errorSnack) {
            notifyError(errorSnackText);
        }
    }, [errorSnack]);

    useEffect(() => {
        if (successSnack) {
            notifySuccess(successSnackText);
        }
    }, [successSnack]);

    if (!localStorage.token) return <Redirect to='/auth/sign-in' />;

    return (
        <>
            <ScrollToTop />
            <Header />
            <Switch>
                {/*<Route path={match.url} exact render={() => <Redirect to={`/main/home`} />} />*/}
                <Redirect from={match.url} exact to={`/main/home`} />
                <Route path={`${match.url}/home`} exact component={HomePage} />
                <Route path={`${match.url}/home/product-:id`} exact component={ProductPage} />
                <Route path={`${match.url}/home/supply`} exact render={() => <ContractPage type='supplier' />} />
                <Route path={`${match.url}/home/invest`} exact render={() => <ContractPage type='investment' />} />
                <Route path={`${match.url}/my-supplies`} exact component={SuppliesPage} />
                <Route path={`${match.url}/my-investments`} exact component={InvestmentsPage} />
                <Route path={`${match.url}/my-supplies/offer-product`} exact component={OfferProduct} />
                <Route render={() => <NotFound />} />
            </Switch>
            <Footer />
            <ToastContainer />
        </>
    );
};

export default Container;
