import React from 'react';
import {Field} from 'redux-form';
import RenderField from '../HelperComponents/RenderField/RenderField';
import {required} from '../../helpers/formValidation';

const InvestFields = () => {
    return (
        <Field
            component={RenderField}
            className='contract-page__input min-w-255'
            type='number'
            name='desired_sum'
            label='How much would you like to invest?'
            placeholder='$ here…'
            validate={required}
        />
    );
};

export default InvestFields;
