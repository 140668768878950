import React from 'react';
import {Field, reduxForm} from 'redux-form';
import RenderField from '../../HelperComponents/RenderField/RenderField';
import {ReCaptchaV2} from '../../HelperComponents/ReCaptchaV2';
import DefaultButton from '../../HelperComponents/Buttons/DefaultButton/DefaultButton';
import {email, required} from '../../../helpers/formValidation';

const SignUpStep1 = ({handleSubmit, pristine, invalid, userRole}) => {
    return (
        <form onSubmit={handleSubmit}>
            <Field
                component={RenderField}
                className='auth-box__input min-w-530 mb-55'
                name='email'
                type='email'
                label='Email'
                validate={[required, email]}
            />
            {userRole.role === 'lender' ? (
                <div className='auth-box__input-group mb-30'>
                    <Field
                        component={RenderField}
                        className='auth-box__input w-48'
                        name='first_name'
                        type='text'
                        label='First name'
                        validate={[required]}
                    />
                    <Field
                        component={RenderField}
                        className='auth-box__input w-48'
                        name='last_name'
                        type='text'
                        label='Last name'
                        validate={[required]}
                    />
                </div>
            ) : (
                <Field
                    component={RenderField}
                    className='auth-box__input min-w-530 mb-30'
                    name='name'
                    type='text'
                    label='Name'
                    validate={[required]}
                />
            )}

            <Field name='recaptcha' component={ReCaptchaV2} validate={required} />

            <div className='auth-box__btn-wrap mt-65 mb-105 mx-auto'>
                <DefaultButton disabled={pristine || invalid} formAction>
                    Next
                </DefaultButton>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'SignUp',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(SignUpStep1);
