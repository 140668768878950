import React from 'react';
import ReactPaginate from 'react-paginate';
import PrevIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';
import './Pagination.scss';

const Pagination = ({active, pageCount, onChange, count, pageLength}) => {
    return (
        <div className='pagination-container'>
            <ReactPaginate
                forcePage={active}
                onPageChange={onChange}
                pageCount={pageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                previousLabel={<PrevIcon />}
                nextLabel={<NextIcon />}
                containerClassName='pagination-list'
                pageClassName='pagination-item'
                pageLinkClassName='pagination-link'
                activeLinkClassName='pagination-link-active'
                breakClassName='pagination-ellipsis'
            />
            <span className='pagination-container__msg'>
                Displaying page {active + 1} of {pageCount}, items {3 * (active + 1) - 2} to {3 * active + pageLength}{' '}
                of {count}
            </span>
        </div>
    );
};

export default Pagination;
