import {HOME} from '../actions/actionTypes';

const INITIAL_STATE = {
    catList: [],
    category: [],
    products: [],
    productItem: {},

    productCategory: ''
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case HOME.CAT_LIST_SUCCESS:
            return {...state, catList: action.payload.data};
        case HOME.CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload.data,
                products: action.payload.data.products
            };
        case HOME.PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload.data
            };
        case HOME.PRODUCT_ITEM_SUCCESS:
            return {
                ...state,
                productItem: action.payload.data,
                productCategory: action.payload.data.category
            };
        default:
            return state;
    }
}