import React, {useEffect, useState} from 'react';
import CardWithStepper from '../HelperComponents/CardWithStepper/CardWithStepper';
import {connect} from 'react-redux';
import {getListInvestmentsContracts, downloadInvestmentFile} from '../../actions/supplyActions';
import Pagination from '../HelperComponents/Pagination/Pagination';
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock';

import '../SuppliesPage/SuppliesPage.scss';

const SuppliesPage = ({getListInvestmentsContracts, investmentsList, downloadInvestmentFile}) => {
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        getListInvestmentsContracts();
    }, []);

    const checkStatus = (status) => {
        switch (status) {
            case 'in_review':
                return 1;
            case 'signature_required':
                return 2;
            case 'contract_in_review':
                return 3;
            case 'investment_received':
                return 4;
            case 'production_process':
                return 5;
            case 'shipping_process':
                return 6;
            case 'customs_clearance':
                return 7;
            case 'fulfillment_center':
                return 8;
            case 'already_delivered':
                return 9;
            case 'payment_received':
                return 10;
            case 'success':
                return 11;
            default:
                return null;
        }
    };

    return (
        <main className='supplies-page page-wrap'>
            <div className='supplies-page__list container py-40'>
                {investmentsList &&
                    investmentsList.results &&
                    investmentsList.results.map((el) => (
                        <CardWithStepper
                            investment={true}
                            key={el.id}
                            id={el.id}
                            status={checkStatus(el.status)}
                            name={(el.product_data && el.product_data.title) || el.product_name}
                            product_data={el.product_data}
                            fulfillment_center={el.fulfillment_center}
                            already_delivered={el.already_delivered}
                            offer={el.offer}
                            downloadFile={downloadInvestmentFile}
                            getData={() => getListInvestmentsContracts(activePage)}
                        />
                    ))}
                {investmentsList.results && investmentsList.results.length === 0 && (
                    <TransitionedBlock tag='h2' className='h1 w-100 t-center'>
                        <span>You made no investments yet :(</span>
                    </TransitionedBlock>
                )}
                {/* <CardWithStepper status={1} />
                <CardWithStepper status={2} />
                <CardWithStepper status={3} />
                <CardWithStepper status={4} /> */}
            </div>

            {investmentsList && investmentsList.count > 3 && (
                <div className='supplies-page__pagination container mb-60'>
                    <Pagination
                        pageCount={investmentsList && Math.ceil(investmentsList.count / 3)}
                        count={investmentsList && investmentsList.count}
                        pageLength={investmentsList && investmentsList.results && investmentsList.results.length}
                        active={activePage - 1}
                        onChange={(e) => {
                            setActivePage(e.selected + 1);
                            getListInvestmentsContracts(e.selected + 1);
                            window.scrollTo({
                                top: 0
                            });
                        }}
                    />
                </div>
            )}
        </main>
    );
};

const mapStateToProps = ({supply}) => {
    return {
        investmentsList: supply.investmentsList
    };
};

const mapDispatchToProps = {getListInvestmentsContracts, downloadInvestmentFile};

export default connect(mapStateToProps, mapDispatchToProps)(SuppliesPage);
