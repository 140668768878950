import React from 'react';
import DefaultButton from '../HelperComponents/Buttons/DefaultButton/DefaultButton';
import DialogComponent from '../HelperComponents/DialogComponent/DialogComponent';

const ContractPageDialog = ({open, onClose}) => {
    return (
        <DialogComponent open={open} onClose={onClose}>
            <h1 className='h1 mb-35'>Request has been sent</h1>
            <p className='mb-30'>
                Thanks for submitting your request. We are working on your loan agreement and will notify you when we
                have uploaded it for your signature
            </p>
            <DefaultButton className='small-btn' variant='outlined' onClick={onClose}>
                Ok
            </DefaultButton>
        </DialogComponent>
    );
};

export default ContractPageDialog;
