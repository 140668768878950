import React, {useEffect, useState} from 'react';
import {Field, reduxForm, SubmissionError, formValueSelector} from 'redux-form';
import {Link, useHistory} from 'react-router-dom';
import SelectComponent from '../../HelperComponents/SelectComponent/SelectComponent';
import RenderField from '../../HelperComponents/RenderField/RenderField';
import {required} from '../../../helpers/formValidation';
import DefaultButton from '../../HelperComponents/Buttons/DefaultButton/DefaultButton';
import OfferProductDialog from './OfferProductDialog';
import {connect, useDispatch, useSelector} from 'react-redux';
import {postOfferProduct} from '../../../actions/supplyActions';

import './OfferProduct.scss';

import {ReactComponent as JarIcon} from '../../../assets/images/jar.svg';
import {ReactComponent as ArrowBlueIcon} from '../../../assets/images/arrow-blue.svg';

const OfferProduct = ({handleSubmit, submitting, pristine, invalid, selectValue}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userRole = useSelector(({auth}) => auth.userInfo.role);
    const {catList} = useSelector(({home}) => home);
    const {buttonLoading} = useSelector(({app}) => app);

    function requireCompany() {
        userRole !== 'company' && history.push('/main/home');
    }

    useEffect(() => {
        requireCompany();
    }, []);

    const options = [...catList.map(({id, name}) => ({value: id, label: name})), {value: 'others', label: 'Others'}];

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => history.push('/main/home');

    const submitForm = (data) => {
        let postData = data;
        if (data && data.product_category && data.product_category.value && data.product_category.value === 'others') {
            postData.product_category = data.other_category;
        } else {
            postData.product_category = data.product_category.label;
        }
        delete postData.other_category;

        return dispatch(postOfferProduct(postData)).then((res) => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                setDialog(true);
            } else {
                throw new SubmissionError({...res.error.response.data, _error: res.error.response.data});
            }
        });
    };

    return (
        <main className='offer-product page-wrap'>
            <JarIcon className='offer-product__icon mt-5' />
            <form
                className='offer-product__form mx-auto mt-140 mb-80 p-40 max-w-770'
                onSubmit={handleSubmit(submitForm)}
            >
                <Link className='backlink' to='/main/my-supplies'>
                    <ArrowBlueIcon />
                    <span>My supplies</span>
                </Link>
                <h1 className='h1 mt-10 mb-70'>Offer my own product</h1>

                <div className='offer-product__field-group'>
                    <div className='offer-product__select mb-70 w-50'>
                        <label className='offer-product__select-label'>Product category (optional)</label>
                        <Field
                            component={SelectComponent}
                            className='w-100'
                            name='product_category'
                            placeholder='Please select the category'
                            options={options}
                            reduxForm
                        />
                    </div>
                    {selectValue && selectValue.value === 'others' && (
                        <Field
                            component={RenderField}
                            className='mb-70 w-50'
                            name='other_category'
                            label='Other category'
                            placeholder='Please specify...'
                            validate={required}
                        />
                    )}
                </div>
                <Field
                    component={RenderField}
                    className='mb-55'
                    name='product_name'
                    label='Product name'
                    placeholder='Please enter the name of the product you want to supply'
                    validate={required}
                />
                <Field
                    component={RenderField}
                    className='mb-55'
                    name='product_specifications'
                    label='Product specifications'
                    placeholder='Please enter the specifications of the product you want to supply...'
                    validate={required}
                    multiline
                />
                <Field
                    component={RenderField}
                    className='mb-55'
                    type='number'
                    name='quantity'
                    label='Quantity'
                    placeholder='Please enter the quantity of the product you want to supply...'
                    validate={required}
                />
                <Field
                    component={RenderField}
                    className='mb-55'
                    type='number'
                    name='price'
                    label='Price'
                    placeholder='Please enter the price of the product you want to supply...'
                    validate={required}
                />
                <Field
                    component={RenderField}
                    name='brand'
                    label='Brand'
                    placeholder='Please enter the brand of the product you want to supply...'
                    validate={required}
                />

                <div className='offer-product__btn-group mt-40'>
                    <DefaultButton
                        className='small-btn gray-btn mr-15'
                        variant='outlined'
                        type='link'
                        to='/main/my-supplies'
                    >
                        Cancel
                    </DefaultButton>
                    <DefaultButton
                        className='small-btn'
                        disabled={submitting || pristine || invalid}
                        loading={buttonLoading}
                        formAction
                    >
                        Submit
                    </DefaultButton>
                </div>
            </form>

            <OfferProductDialog open={dialog} onClose={closeDialog} />
        </main>
    );
};

const OfferProductForm = reduxForm({
    form: 'OfferProduct'
})(OfferProduct);

const selector = formValueSelector('OfferProduct');

const mapStateToProps = (state) => {
    return {
        selectValue: selector(state, 'product_category')
    };
};

export default connect(mapStateToProps)(OfferProductForm);

// export default reduxForm({
//     form: 'OfferProduct'
// })(OfferProduct);
