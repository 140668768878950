import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getProductItem} from '../../actions/homeActions';
import moment from 'moment';
import Countdown, {zeroPad} from 'react-countdown';
import CategoryNav from '../CategoryNav/CategoryNav';
import DefaultButton from '../HelperComponents/Buttons/DefaultButton/DefaultButton';
import LoaderMUI from '../HelperComponents/LoaderMUI/LoaderMUI';

import './ProductPage.scss';

import {ReactComponent as BackArrowIcon} from '../../assets/images/back-arrow.svg';
import {ReactComponent as DocIcon} from '../../assets/images/doc.svg';
import {ReactComponent as ClockIcon} from '../../assets/images/clock.svg';
import placeholder from '../../assets/images/placeholder.png';

const ProductPage = ({history}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const userRole = useSelector(({auth}) => auth.userInfo.role);
    const {loading} = useSelector(({app}) => app);
    const {catList, productItem} = useSelector(({home}) => home);

    useEffect(() => {
        dispatch(getProductItem(id));
    }, []);

    const {
        title,
        descr,
        image,
        minimum_term,
        finish_date,
        start_date,
        interest_rate,
        min_investment,
        max_investment,
        lender_offer,
        supplier_offer,
        category
    } = productItem;

    const minimumTerm = moment(minimum_term).format('MMMM DD, YYYY');
    const createDate = moment.utc(start_date);
    const createDiff = moment([]).diff(createDate, 'days');
    let finishDate = moment.utc(finish_date).format('YYYY-MM-DD HH:mm:ss');
    if (!finish_date) finishDate = new Date();
    const finishDiff = moment.utc(finish_date).diff([], 'days');

    const renderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a complete state
            return <span>Finished</span>;
        } else {
            // Render a countdown
            if (finishDiff > 1) {
                return (
                    <span>
                        {days} days {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                );
            } else if (finishDiff === 1) {
                return (
                    <span>
                        {days} day {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                );
            } else if (finishDiff < 1) {
                return (
                    <span>
                        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                );
            }
        }
    };

    const investContract = () => {
        history.push(`/main/home/invest`, {title, id});
    };

    const supplyContract = () => {
        let catName = catList.find((cat) => cat.id === category).name;
        history.push(`/main/home/supply`, {title, id, catName});
    };

    return (
        <main className='product-page page-wrap'>
            <div className='product-page__cat-nav'>
                <CategoryNav className='container' links={catList} />
            </div>
            {loading ? (
                <LoaderMUI linear />
            ) : (
                <>
                    <header className='product-page__header container py-50'>
                        <div className='product-page__title-wrap'>
                            <button
                                className='product-page__back good-hover'
                                onClick={() => history.goBack()}
                                aria-label='Backlink'
                            >
                                <BackArrowIcon />
                            </button>
                            <h1 className='product-page__title'>{title ? title : 'No data'}</h1>
                        </div>

                        <img
                            className='product-page__img'
                            src={image ? image : placeholder}
                            alt={title ? title : 'Product image'}
                        />

                        <div className='product-page-info'>
                            <h2 className='product-page-info__title'>Invest data</h2>

                            <p className='product-page-info__item good'>
                                <span className='product-page-info__desc'>Interest rate </span>
                                {interest_rate ? `${interest_rate}%` : 'No data'}
                            </p>
                            <p className='product-page-info__item'>
                                <span className='product-page-info__desc'>Investment boundaries </span>
                                {min_investment && max_investment && `$${min_investment}-${max_investment}`}
                                {min_investment && !max_investment && `From $${min_investment}`}
                                {!min_investment && max_investment && `Up to $${max_investment}`}
                                {!min_investment && !max_investment && 'No data'}
                            </p>
                            <p className='product-page-info__item'>
                                <span className='product-page-info__desc'>Minimum term </span>
                                {minimumTerm ? minimumTerm : 'No data'}
                            </p>
                            <hr className='product-page-info__sep' />

                            {(lender_offer || supplier_offer) && (
                                <>
                                    {lender_offer && (
                                        <a
                                            className='product-page-info__doc-btn good-hover'
                                            href={lender_offer}
                                            download={lender_offer.split('/')[lender_offer.split('/').length - 1]}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <DocIcon />
                                            {userRole === 'company' ? 'Lend offer' : 'Download offer'}
                                        </a>
                                    )}
                                    {lender_offer && userRole === 'company' && (
                                        <a
                                            className='product-page-info__doc-btn good-hover'
                                            href={supplier_offer}
                                            download={supplier_offer.split('/')[supplier_offer.split('/').length - 1]}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <DocIcon />
                                            Supply offer
                                        </a>
                                    )}
                                    <hr className='product-page-info__sep' />
                                </>
                            )}

                            <p className={`product-page-info__time${finishDiff < 2 ? ' alert' : ''}`}>
                                <ClockIcon />
                                <span className='product-page-info__time--desc'>Time left: </span>
                                {finish_date ? <Countdown date={finishDate} renderer={renderer} /> : 'No data'}
                                {finishDiff < 2 && <span className='ml-5 good'>Last chance</span>}
                                {createDiff <= 2 && finishDiff >= 2 && <span className='ml-5 good'>New</span>}
                            </p>

                            <div
                                className={`product-page-info__btn-group${
                                    userRole === 'company' ? ' product-page-info__btn-group--supplier' : ''
                                }`}
                            >
                                {userRole === 'company' ? (
                                    <>
                                        <DefaultButton
                                            className='product-page-info__btn gray-btn'
                                            variant='outlined'
                                            onClick={investContract}
                                        >
                                            Invest now
                                        </DefaultButton>
                                        <DefaultButton className='product-page-info__btn' onClick={supplyContract}>
                                            Supply
                                        </DefaultButton>
                                    </>
                                ) : (
                                    <DefaultButton
                                        className='product-page-info__btn full-width-btn'
                                        onClick={investContract}
                                    >
                                        Invest now
                                    </DefaultButton>
                                )}
                            </div>
                        </div>
                    </header>
                    {descr && (
                        <section className='product-page__desc pt-65 pb-110'>
                            <div className='container' dangerouslySetInnerHTML={{__html: descr}} />
                        </section>
                    )}
                </>
            )}
        </main>
    );
};

export default ProductPage;
