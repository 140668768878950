import React from 'react';

const ContractPageInfo = () => {
    return (
        <>
            <h2 className='h3 mb-55'>
                Before you invest, it’s important that you read and understand the information below:
            </h2>
            <div className='contract-page__info-wrap mb-55 max-w-1100'>
                <article className='contract-page__info-item'>
                    <h3 className='h3 mb-10'>Investing is risky</h3>
                    <p>
                        Investing in early stage companies is risky business. You should be in a financial condition
                        where you can comfortably afford to lose your entire investment. You should always be aware of
                        your personal investment limits.
                    </p>
                </article>
                <article className='contract-page__info-item'>
                    <h3 className='h3 mb-10'>Do your research</h3>
                    <p>
                        Although we collect information on each company we list, you must rely on your own examination
                        of the company, the terms of the offering, and the risks involved to make your investment
                        decision.
                    </p>
                </article>
                <article className='contract-page__info-item'>
                    <h3 className='h3 mb-10'>Canceling your investment</h3>
                    <p>
                        You may cancel your investment until 10:59pm EDT on June 28, 2021, which is 48 hours before the
                        deadline of this offering. If there is a material change to the terms of this offering, we’ll
                        provide notification, and you must confirm your commitment within five days or it will be
                        automatically withdrawn.
                    </p>
                </article>
                <article className='contract-page__info-item'>
                    <h3 className='h3 mb-10'>Reselling your investment in GenesisAI</h3>
                    <p>
                        It may be difficult to resell your shares of GenesisAI. You may not transfer these securities
                        until one year after they are issued, except under very specific circumstances. Even after the
                        one year holding period, these securities may be difficult to resell.
                    </p>
                </article>
                <article className='contract-page__info-item'>
                    <h3 className='h3 mb-10'>Returns on your investment</h3>
                    <p>
                        GenesisAI may be years away from providing a return on investment, if ever. During that time,
                        your ownership may be diluted, and your voting power may be limited.
                    </p>
                </article>
                <article className='contract-page__info-item'>
                    <h3 className='h3 mb-10'>Returns on your investment</h3>
                    <p>
                        GenesisAI may be years away from providing a return on investment, if ever. During that time,
                        your ownership may be diluted, and your voting power may be limited.
                    </p>
                </article>
            </div>
        </>
    );
};

export default ContractPageInfo;
