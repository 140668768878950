import {HOME} from './actionTypes';

export function getCatList() {
    return {
        type: HOME.CAT_LIST,
        payload: {
            client: 'default',
            request: {
                url: `category/`,
                method: 'get'
            }
        }
    };
}

export function getCategory(id) {
    return {
        type: HOME.CATEGORY,
        payload: {
            client: 'default',
            request: {
                url: `category/${id}`,
                method: 'get'
            }
        }
    };
}

export function getProducts() {
    return {
        type: HOME.PRODUCTS,
        payload: {
            client: 'default',
            request: {
                url: `product/`,
                method: 'get'
            }
        }
    };
}

export function getProductItem(id) {
    return {
        type: HOME.PRODUCT_ITEM,
        payload: {
            client: 'default',
            request: {
                url: `product/${id}`,
                method: 'get'
            }
        }
    };
}

export function postContract(data, type) {
    return {
        type: HOME.CONTRACT,
        payload: {
            client: 'default',
            request: {
                url: `contract/${type}/`,
                method: 'post',
                data
            }
        }
    };
}
