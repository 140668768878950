import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getCatList} from '../../actions/homeActions';
import CategoryNav from '../CategoryNav/CategoryNav';

import './Footer.scss';

import {ReactComponent as LinkedinIcon} from '../../assets/images/linkedin.svg';
import {ReactComponent as FacebookIcon} from '../../assets/images/facebook.svg';
import {ReactComponent as InstagramIcon} from '../../assets/images/instagram.svg';
import {ReactComponent as TwitterIcon} from '../../assets/images/twitter.svg';

const Footer = () => {
    const dispatch = useDispatch();
    const {catList} = useSelector(({home}) => home);

    useEffect(() => {
        dispatch(getCatList());
    }, []);

    return (
        <footer className='footer'>
            <div className='footer__container container'>
                <nav className='footer__cat'>
                    <CategoryNav links={catList} allLink={false} />
                </nav>

                <div className='footer__info'>
                    <NavLink className='footer__link' to='/main/help-center'>
                        Help center
                    </NavLink>
                    <NavLink className='footer__link' to='/main/about'>
                        About
                    </NavLink>
                </div>

                <ul className='footer__socials'>
                    <li className='social-item'>
                        <a
                            className='social-item__link'
                            href='https://www.linkedin.com/company/afrecon-investment-corporation-ltd'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Linkedin'
                        >
                            <LinkedinIcon className='social-item__icon' />
                        </a>
                    </li>
                    <li className='social-item'>
                        <a
                            className='social-item__link'
                            href='https://www.facebook.com/Viebeg-Medical-353976595340596'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Facebook'
                        >
                            <FacebookIcon className='social-item__icon' />
                        </a>
                    </li>
                    <li className='social-item'>
                        <a
                            className='social-item__link'
                            href='https://www.instagram.com/viebeg.technologies'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Instagram'
                        >
                            <InstagramIcon className='social-item__icon' />
                        </a>
                    </li>
                    <li className='social-item'>
                        <a
                            className='social-item__link'
                            href='https://twitter.com/viebegm'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Twitter'
                        >
                            <TwitterIcon className='social-item__icon' />
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    );
};


export default Footer;
