import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';
import {closeErrorSnack} from '../../actions/appActions';

import './AuthContainer.scss';

import {ReactComponent as AuthLogo} from '../../assets/images/auth-logo.svg';

const AuthContainer = ({children}) => {
    if (localStorage.token) return <Redirect to='/main' />;

    return (
        <main className='auth-page'>
            <div className='auth-page__bg p-40 w-35'>
                <Link className='auth-page__logo-link good-hover' to='/auth/sign-in'>
                    <AuthLogo className='auth-page__logo' />
                </Link>
            </div>
            <div className='auth-page__content p-15 w-65'>
                {children}
            </div>
        </main>
    );
};

const mapStateToProps = ({app}) => {
    return {
        errorSnack: app.errorSnack,
        errorSnackText: app.errorSnackText
    };
};

const mapDispatchToProps = {
    closeErrorSnack
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
