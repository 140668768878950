import React from 'react';
import DialogComponent from '../../HelperComponents/DialogComponent/DialogComponent';
import DefaultButton from '../../HelperComponents/Buttons/DefaultButton/DefaultButton';

const OfferProductDialog = ({open, onClose}) => {
    return (
        <DialogComponent open={open} onClose={onClose}>
            <h1 className='h1 mb-35'>Form has been sent</h1>
            <p className='mb-30'>
                Thank you for submitting your request to supply with Viebeg.
                <br />
                Our team will get back to you shortly!
            </p>
            <DefaultButton className='small-btn' variant='outlined' onClick={onClose}>
                Ok
            </DefaultButton>
        </DialogComponent>
    );
};

export default OfferProductDialog;
