import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, Link, useHistory} from 'react-router-dom';
import {getUserinfo} from '../../actions/authActions';
import {Skeleton} from '@material-ui/lab';

import './Header.scss';

import {ReactComponent as HeaderLogo} from '../../assets/images/logo.svg';
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg';
import {ReactComponent as FolderIcon} from '../../assets/images/folder.svg';
import {ReactComponent as BoxIcon} from '../../assets/images/box.svg';
import {ReactComponent as LogoutIcon} from '../../assets/images/logout.svg';

const Header = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {email, first_name, last_name, name, role} = useSelector(({auth}) => auth.userInfo);

    useEffect(() => {
        dispatch(getUserinfo());
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        history.push('/auth/sign-in');
    };

    let userName;
    if (role === 'lender') {
        userName = `${first_name} ${last_name}`;
    } else {
        userName = name;
    }

    return (
        <header className='header'>
            <div className='header__container container'>
                <Link className='good-hover my-auto' to='/main/home'>
                    <HeaderLogo className='header__logo' />
                </Link>

                {role && (
                    <nav className='header__nav'>
                        <NavLink className='header__nav-link' to='/main/home'>
                            <PlusIcon className='header__icon' />
                            Home
                        </NavLink>
                        {role === 'company' && (
                            <NavLink className='header__nav-link' to='/main/my-supplies'>
                                <BoxIcon className='header__icon' />
                                My Supplies
                            </NavLink>
                        )}
                        <NavLink className='header__nav-link' to='/main/my-investments'>
                            <FolderIcon className='header__icon' />
                            My investments
                        </NavLink>
                    </nav>
                )}

                <div className='header__user-info my-auto'>
                    <span className='header__user-name'>{userName ? userName : <Skeleton width='115px' />}</span>
                    <span className='header__user-mail'>{email ? email : <Skeleton />}</span>
                </div>
                <button className='header__logout my-auto' aria-label='Logout button' onClick={logout}>
                    <LogoutIcon className='header__icon' />
                </button>
            </div>
        </header>
    );
};

export default Header;
