let BASE_URL, SECOND_URL;

const host = window.location.host;

if (host.includes('localhost') || host === "crowdsupply.4-com.pro") {
    BASE_URL = 'http://api.crowdsupply.4-com.pro/v0/';
} else {
    BASE_URL = `https://api.crowdsupply.vieprocure.com/v0/`;
}

SECOND_URL = '';

export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
