import React from 'react';
import App from '../containers/App';
import {Route, Switch, Redirect} from 'react-router-dom';
import AuthRoutes from './authRoutes';
import Container from '../containers/Container/Container';
import AuthContainer from '../containers/AuthContainer/AuthContainer';
import SignUp from '../components/Auth/SignUp/SignUp';
import NotFound from '../components/NotFound/NotFound';

export default (
    <App>
        <Switch>
            <Route
                path='/'
                exact
                render={() => (localStorage.token ? <Redirect to='/main' /> : <Redirect to='/auth/sign-in' />)}
            />
            <Route path='/auth' component={AuthRoutes} />
            <Route path='/main' component={Container} />

            <AuthContainer>
                <Switch>
                    <Route path='/lender' exact component={SignUp} />
                    <Route path='/supplier' exact component={SignUp} />
                    <Route render={() => <NotFound />} />
                </Switch>
            </AuthContainer>

            <Route render={() => <NotFound />} />
        </Switch>
    </App>
);
