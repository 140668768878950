import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import moment from 'moment';
import Countdown, {zeroPad} from 'react-countdown';
import DefaultButton from '../HelperComponents/Buttons/DefaultButton/DefaultButton';

import './ProductCard.scss';

import placeholder from '../../assets/images/placeholder.png';
import {ReactComponent as ClockIcon} from '../../assets/images/clock.svg';

const ProductCard = ({data, userRole, className = ''}) => {
    const {id, title, image_url, interest_rate, minimum_term, finish_date, category_id, start_date} = data;
    const history = useHistory();
    const {catList} = useSelector(({home}) => home);

    const pathToInner = `/main/home/product-${id}`;

    const minimumTerm = moment(minimum_term).format('MMMM DD, YYYY');
    const createDate = moment.utc(start_date);
    const createDiff = moment([]).diff(createDate, 'days');
    let finishDate = moment.utc(finish_date).format('YYYY-MM-DD HH:mm:ss');
    if (!finish_date) finishDate = new Date();
    const finishDiff = moment.utc(finish_date).diff([], 'days');

    const renderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a complete state
            return <span>Finished</span>;
        } else {
            // Render a countdown
            if (finishDiff > 1) {
                return (
                    <span>
                        {days} days {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                );
            } else if (finishDiff === 1) {
                return (
                    <span>
                        {days} day {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                );
            } else if (finishDiff < 1) {
                return (
                    <span>
                        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                );
            }
        }
    };

    const investContract = () => {
        history.push(`/main/home/invest`, {title, id});
    };

    const supplyContract = () => {
        const catName = catList.find((cat) => cat.id === category_id).name;
        history.push(`/main/home/supply`, {title, id, catName});
    };

    return (
        <article className={`product-card${className && ` ${className}`}`}>
            <Link className='product-card__link' to={pathToInner}>
                <div className='product-card__wrap'>
                    <span className={`product-card__status ${finishDiff < 2 ? 'alert' : 'good'}`}>
                        {finishDiff < 2 && 'Last chance'}
                        {createDiff <= 2 && finishDiff >= 2 && 'New'}
                    </span>
                    <h2 className='product-card__title'>{title ? title : 'No data'}</h2>
                </div>
                <img
                    className='product-card__img'
                    src={image_url ? image_url : placeholder}
                    alt={title ? title : 'Product image'}
                />
            </Link>
            <div className='product-card__wrap'>
                <Link className='product-card__link' to={pathToInner}>
                    <p className='product-card__item good'>
                        <span className='product-card__desc'>Interest rate </span>
                        {interest_rate ? `${interest_rate}%` : 'No data'}
                    </p>
                    <p className='product-card__item'>
                        <span className='product-card__desc'>Minimum term </span>
                        {minimumTerm ? minimumTerm : 'No data'}
                    </p>
                    <hr className='product-card__sep' />
                    <p className={`product-card__time${finishDiff < 2 ? ' alert' : ''}`}>
                        <ClockIcon />
                        <span className='product-card__time--desc'>Time left: </span>
                        {finish_date ? <Countdown date={finishDate} renderer={renderer} /> : 'No data'}
                    </p>
                </Link>

                <div
                    className={`product-card__btn-group${
                        userRole === 'company' ? ' product-card__btn-group--supplier' : ''
                    }`}
                >
                    {userRole === 'company' ? (
                        <>
                            <DefaultButton
                                className='product-card__btn gray-btn'
                                variant='outlined'
                                onClick={investContract}
                            >
                                Invest now
                            </DefaultButton>
                            <DefaultButton className='product-card__btn gray-btn' onClick={supplyContract}>
                                Supply
                            </DefaultButton>
                        </>
                    ) : (
                        <DefaultButton className='product-card__btn full-width-btn gray-btn' onClick={investContract}>
                            Invest now
                        </DefaultButton>
                    )}
                </div>
            </div>
        </article>
    );
};

export default ProductCard;
