import React, {useEffect, useState} from 'react';
import {SubmissionError, reset} from 'redux-form';
import {useDispatch, useSelector} from 'react-redux';
import {postSignUp} from '../../../actions/authActions';
import {useToggle} from '../../../helpers/functions';
import {Link} from 'react-router-dom';
import SignUpStep1 from './SignUpStep1';
import SignUpStep2 from './SignUpStep2';
import SignUpStep3 from './SignUpStep3';
import SignUpSuccessDialog from './SignUpSuccessDialog';
import SnackbarMUI from '../../HelperComponents/SnackbarMUI/SnackbarMUI';
import {captchaReset} from '../../HelperComponents/ReCaptchaV2';

import {ReactComponent as ArrowBlueIcon} from '../../../assets/images/arrow-blue.svg';

const SignUp = ({match}) => {
    const dispatch = useDispatch();
    const {loading} = useSelector(({app}) => app);
    const {signUpError} = useSelector(({auth}) => auth);
    const [userRole, setUserRole] = useState({role: ''});

    useEffect(() => {
        if (match.url === '/lender') {
            setUserRole({role: 'lender'});
        } else if (match.url === '/supplier') {
            setUserRole({role: 'company'});
        }
    }, []);

    const [page, setPage] = useState(1);
    const nextPage = () => setPage((prev) => prev + 1);
    const previousPage = () => setPage((prev) => prev - 1);

    let descText;
    if (page === 1) {
        descText = 'Please, provide some information about yourself';
    } else if (page === 2) {
        descText = 'Please, fill out the form about your location at the moment';
    } else if (page === 3) {
        descText = 'Just a little bit left, create a password for your personal account';
    }

    const [dialog, toggleDialog] = useToggle(false);
    const [error, toggleError] = useToggle(false);
    const onSignUpSuccess = () => {
        toggleDialog();
        dispatch(reset('SignUp'));
        setPage(1);
    };

    const submitForm = (data) => {
        return dispatch(postSignUp({...data, ...userRole})).then((res) => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                onSignUpSuccess();
            } else {
                toggleError();
                captchaReset();
                throw new SubmissionError({...res.error.response.data, _error: res.error.response.data.detail});
            }
        });
    };

    return (
        <div className='auth-box max-w-530'>
            {(page === 2 || page === 3) && (
                <button type='button' className='backlink mb-10' onClick={previousPage}>
                    <ArrowBlueIcon />
                    <span>{page === 2 ? 'Step 1' : 'Step 2'}</span>
                </button>
            )}

            <h1 className='h1 mb-15'>Sign up to Vieprocure</h1>
            <p className='mb-85'>{descText}</p>

            {page === 1 && <SignUpStep1 userRole={userRole} onSubmit={nextPage} />}
            {page === 2 && <SignUpStep2 onSubmit={nextPage} />}
            {page === 3 && <SignUpStep3 onSubmit={submitForm} loading={loading} />}

            <div className='auth-box__footer'>
                <span className='auth-box__text mr-15'>Already a Vieprocure member?</span>
                <Link className='auth-box__link' to='/auth/sign-in'>
                    SIGN IN
                </Link>
            </div>

            <SnackbarMUI open={error} autoHideDuration={6000} onClose={toggleError} errors={signUpError} />

            <SignUpSuccessDialog open={dialog} onClose={toggleDialog} />
        </div>
    );
};

export default SignUp;
