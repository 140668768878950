import React from 'react';
import DialogComponent from '../../HelperComponents/DialogComponent/DialogComponent';
import DefaultButton from '../../HelperComponents/Buttons/DefaultButton/DefaultButton';

const SuccessAuthDialog = ({open, onClose}) => {
    return (
        <DialogComponent open={open} onClose={onClose}>
            <h1 className='h1 mb-35'>Thank you for your registration</h1>
            <p className='mb-30'>We have sent you an email with a link to confirm your registration.</p>
            <DefaultButton className='small-btn' variant='outlined' type='link' to='/auth/sign-in'>
                Ok
            </DefaultButton>
        </DialogComponent>
    );
};

export default SuccessAuthDialog;
