import {takeEvery} from 'redux-saga/effects';
import {
    disableLoader,
    enableLoader,
    disableButtonLoader,
    enableButtonLoader,
    openErrorSnack
} from './rootSaga';

export function* handleHomeLoaders() {
    yield takeEvery('CAT_LIST', enableLoader);
    yield takeEvery('CAT_LIST_SUCCESS', disableLoader);
    yield takeEvery('CAT_LIST_FAIL', disableLoader);

    yield takeEvery('CATEGORY', enableLoader);
    yield takeEvery('CATEGORY_SUCCESS', disableLoader);
    yield takeEvery('CATEGORY_FAIL', disableLoader);

    yield takeEvery('PRODUCTS', enableLoader);
    yield takeEvery('PRODUCTS_SUCCESS', disableLoader);
    yield takeEvery('PRODUCTS_FAIL', disableLoader);

    yield takeEvery('PRODUCT_ITEM', enableLoader);
    yield takeEvery('PRODUCT_ITEM_SUCCESS', disableLoader);
    yield takeEvery('PRODUCT_ITEM_FAIL', disableLoader);

    yield takeEvery('CONTRACT', enableButtonLoader);
    yield takeEvery('CONTRACT_SUCCESS', disableButtonLoader);
    yield takeEvery('CONTRACT_FAIL', disableButtonLoader);
}

export function* handleHomeErrors() {
    yield takeEvery('CAT_LIST_FAIL', openErrorSnack);
    yield takeEvery('CATEGORY_FAIL', openErrorSnack);
    yield takeEvery('PRODUCTS_FAIL', openErrorSnack);
    yield takeEvery('PRODUCT_ITEM_FAIL', openErrorSnack);
    yield takeEvery('CONTRACT_FAIL', openErrorSnack);
}
