import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import AuthContainer from '../containers/AuthContainer/AuthContainer';
import SignIn from '../components/Auth/SignIn/SignIn';
import PasswordRecovery from '../components/Auth/PasswordRecovery/PasswordRecovery';
import PasswordConfirm from '../components/Auth/PasswordRecovery/PasswordConfirm';
import NotFound from '../components/NotFound/NotFound';

export default function AuthRoutes() {
    return (
        <AuthContainer>
            <Switch>
                <Redirect from='/auth' exact to={`/auth/sign-in`} />
                <Route path={`/auth/sign-in`} exact component={SignIn} />
                <Route path={`/auth/password-recovery`} exact component={PasswordRecovery} />
                <Route path={`/auth/password-confirm`} exact component={PasswordConfirm} />
                <Route render={() => <NotFound />} />
            </Switch>
        </AuthContainer>
    );
}
