import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCategory, getProducts} from '../../actions/homeActions';
import {useQueryParams} from '../../helpers/functions';
import CategoryNav from '../CategoryNav/CategoryNav';
import LoaderMUI from '../HelperComponents/LoaderMUI/LoaderMUI';
import ProductCard from '../ProductCard/ProductCard';
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock';

import './HomePage.scss';

const HomePage = ({location}) => {
    const dispatch = useDispatch();
    const userRole = useSelector(({auth}) => auth.userInfo.role);
    const {loading} = useSelector(({app}) => app);
    const {products, catList} = useSelector(({home}) => home);

    const query = location.search;
    const queryCatId = useQueryParams().get('category');

    useEffect(() => {
        query.length < 1 && dispatch(getProducts());
        query.length > 1 && dispatch(getCategory(queryCatId));
    }, [query]);

    return (
        <main className='home-page page-wrap'>
            <div className='home-page__cat-nav'>
                <CategoryNav className='container' links={catList} />
            </div>
            {loading ? (
                <LoaderMUI linear />
            ) : (
                <div className={`${products.length > 0 ? 'home-page__products ' : ''}container py-40`}>
                    {products.length > 0 ? (
                        products.map((product) => <ProductCard key={product.id} data={product} userRole={userRole} />)
                    ) : (
                        <TransitionedBlock tag='h2' className='h1 w-100 t-center'>
                            <span>There are no products in this category :(</span>
                        </TransitionedBlock>
                    )}
                </div>
            )}
        </main>
    );
};

export default HomePage;
