import {takeEvery} from 'redux-saga/effects';
// import { getProfile } from "../actions/authActions";
import {
    disableLoader,
    enableLoader,
    // openSuccessSnack,
    openErrorSnack,
    disableButtonLoader,
    enableButtonLoader
} from './rootSaga';

export function* handleProfileActions() {
    // yield takeEvery("PATCH_PROFILE_SUCCESS", fetchProfile);
}

// function* fetchProfile() {
//     yield put(getProfile());
// }

export function* handleProfileLoader() {
    // yield takeEvery('GET_PROFILE', enableLoader);
    // yield takeEvery('GET_PROFILE_SUCCESS', disableLoader);
    // yield takeEvery('GET_PROFILE_FAIL', disableLoader);
}

export function* handleProfileSnacks() {
    // yield takeEvery('PATCH_PROFILE_SUCCESS', () => openSuccessSnack('Changes successfully updated'));
    // yield takeEvery('PATCH_PROFILE_FAIL', () => openErrorSnack({error: 'Something went wrong'}));
    yield takeEvery('USER_INFO_FAIL', openErrorSnack);
}

export function* handleAuthLoaders() {
    yield takeEvery('SIGN_IN', enableButtonLoader);
    yield takeEvery('SIGN_IN_SUCCESS', disableButtonLoader);
    yield takeEvery('SIGN_IN_FAIL', disableButtonLoader);

    yield takeEvery('SIGN_UP', enableLoader);
    yield takeEvery('SIGN_UP_SUCCESS', disableLoader);
    yield takeEvery('SIGN_UP_FAIL', disableLoader);

    yield takeEvery('EMAIL_VER', enableLoader);
    yield takeEvery('EMAIL_VER_SUCCESS', disableLoader);
    yield takeEvery('EMAIL_VER_FAIL', disableLoader);

    yield takeEvery('PASS_RECOVERY', enableLoader);
    yield takeEvery('PASS_RECOVERY_SUCCESS', disableLoader);
    yield takeEvery('PASS_RECOVERY_FAIL', disableLoader);

    yield takeEvery('PASS_CONFIRM', enableLoader);
    yield takeEvery('PASS_CONFIRM_SUCCESS', disableLoader);
    yield takeEvery('PASS_CONFIRM_FAIL', disableLoader);
}
